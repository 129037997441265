<template>
  <!-- Card Header Breadcrumb -->
  <div class="col-12 col-md text-center text-md-right">
    <ul class="bc-list text-dark">
      <li v-if="breadCrumb && breadCrumb.length > 0">
        <router-link to="/categories">{{ $t("categories") }}</router-link>
      </li>
      <li v-for="(bc, i) in breadCrumb" v-bind:key="i">
        <router-link v-bind:to="'/categories/children/' + bc.id">{{
          bc.name
        }}</router-link>
      </li>
    </ul>
  </div>
  <!-- ./Card Header Breadcrumb -->
</template>

<script>
export default {
  name: "BreadCrumb",
  props: {
    breadCrumb: {
      default: "",
    },
  },
};
</script>
